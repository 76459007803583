<template>
  <div class="history-overview-wrapper">
    <!-- Mobile Tab -->
    <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters justify="center">
      <v-card class="mobile-history-card" elevation="0">
        <v-col class="text-center" cols="12">
          <v-btn class="history-tab-mobile" :color="selectedTab == 'RUNNING' ? 'primary' : ''" depressed
                 @click="onChangeTab('RUNNING')">
            <v-icon class="mobile-icon">cached</v-icon>
            <span>
              <v-badge
                  v-if="selectedTab == 'RUNNING' && turnoverTicket.length >0"
                  bordered
                  color="error"
                  :content="turnoverTicket.length"
              >
                <span>{{ $t(`label.running`) }}
                </span>
              </v-badge>
             <span v-else>{{ $t(`label.running`) }}
                </span>
            </span>
          </v-btn>
          <v-btn class="history-tab-mobile" :color="selectedTab == 'COMPLETED' ? 'primary' : ''" depressed
                 @click="onChangeTab('COMPLETED')">
            <v-icon class="mobile-icon">sports_soccer</v-icon>
            <span>{{ $t(`label.completed`) }}</span>
          </v-btn>
        </v-col>
      </v-card>
    </v-row>
    <!-- End Mobile Tab -->

    <v-card elevation="0">
      <!-- <v-card-title class="justify-space-between" v-if="$vuetify.breakpoint.mdAndUp">
        <label class="--v-primary-base text-left">{{ $t(`label.playerHistory`) }}</label>
      </v-card-title> -->
      <!-- Desktop Tab -->
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10 pb-3"
             style="border-bottom: 1px dashed black;">
        <v-col class="text-right mt-3" cols="12">
          <v-btn class="player-history-tab" :color="selectedTab == 'RUNNING' ? 'primary' : ''" depressed
                 @click="onChangeTab('RUNNING')">
             <span>
              <v-badge
                  v-if="selectedTab == 'RUNNING' && turnoverTicket.length > 0"
                  bordered
                  color="error"
                  :content="turnoverTicket.length"
              >
                <span>{{ $t(`label.running`) }}
                </span>
              </v-badge>
             <span v-else>{{ $t(`label.running`) }}
                </span>
            </span>
          </v-btn>
          <v-btn class="player-history-tab" :color="selectedTab == 'COMPLETED' ? 'primary' : ''" depressed
                 @click="onChangeTab('COMPLETED')">
            {{ $t(`label.completed`) }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- End Desktop Tab -->
      <v-card class="history-card" elevation="0">
        <!-- Desktop -->
        <v-row no-gutters>
          <v-col cols="12" v-if="turnoverTicket.length <= 0">
            <p class="text-center my-4">No Pending Ticket Found !</p>
          </v-col>
          <!-- Running -->
          <v-col cols="12" class="all-wallet-wrap px-5" v-else>
            <div id="turnover-16065131" :class="['bonus-wallet-wrap', n.is_served ? 'served' : 'progressing']"
                 v-for="(n,k) in turnoverTicket"
                 :title="n.remark">
              <div class="bonus-wallet">
                <div class="title" ui-key="title">{{ n.remark }}</div>
                <div :class="[n.is_served ? 'wallet-label-hot' : 'wallet-label now']"></div>
                <div class="wallet-inner">

                  <div class="wallet-inner-left bg-load data-image" data-image="image_7437.jpg" data-preload=""></div>

                  <div class="wallet-inner-right" data-messagetype="1" data-id="16065131">
                    <div class="card-detail">
                      <div class="card-date end_time">{{ $t(`label.event_end`) }}
                        {{ new Date(new Date().getFullYear(), 11, 31).toLocaleDateString() }}
                      </div>
                      <div class="detail-btn">
                        <a>
                          <span @click="setDetailsDialog(n)">{{ $t(`label.detail`) }}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div class="discount">
                      &nbsp;
                      <div class="currency">৳</div>
                      <div class="amount">{{ n.current_progress | floatParser }}</div>
                    </div>
                    <div class="discount02">

                      <div class="turnover">
                        <span>{{ $t(`label.turnover`) }}</span>
                        ：X<span>{{ n.multiplier }}</span>
                      </div>
                    </div>
                    <div class="progress-group">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                             aria-valuemax="100"
                             :style="'width:' + (getProgressBar(n)) + '%'"></div>
                      </div>
                      <span class="vip-now">{{ n.current_progress | floatParser }}</span>
                      <span class="vip-next" ui-key="requirement_turnover">{{ n.total_rollover | floatParser }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <!-- End Overview -->
        </v-row>
        <!-- End Desktop -->
      </v-card>
    </v-card>
    <v-row justify="center">
      <v-dialog
          v-model="detailDialog"
          max-width="420"
      >
        <v-card>
          <v-card-title class="subtitle-1 bg-pop white--text">
            {{ detailObj.title }}
          </v-card-title>
          <div class="pop-wrap pop-inner article">
            <table>
              <tbody>
              <tr>
                <td>
                  <span>{{ $t(`label.transAmount`) }}</span>
                </td>
                <td>{{ detailObj.deposit_amount | floatParser }}</td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t(`label.bonus`) }}</span>
                </td>
                <td>{{ detailObj.bonus_amount | floatParser }}</td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t(`label.to_req`) }}</span>
                </td>
                <td>{{ detailObj.to_req | floatParser }}</td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t(`label.to_completed`) }}</span>
                </td>
                <td>{{ detailObj.to_completed | floatParser }}</td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t(`label.to_ratio`) }}</span>
                </td>
                <td>{{ detailObj.to_ratio | floatParser }}%</td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t(`label.to_created_time`) }}</span>
                </td>
                <td>{{ detailObj.to_create_time | longDate }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper, locale, errorCodeHelper, formValidator, ddlHandler} from '@/util'
import {SHARED} from '@/constants/constants'
import _ from 'lodash'
import {HISTORY_OVERVIEW, HISTORY_DETAIL} from '@/store/history.module'
import {MEMBER_TURNOVER_TICKET} from "@/store/member.module";

export default {
  name: 'playerHistory',
  data: function () {
    return {
      detailObj: {
        'title': '',
        'deposit_amount': '',
        'bonus_amount': '',
        'to_req': '',
        'to_completed': '',
        'to_ratio': '',
        'to_create_time': ''
      },
      detailDialog: false,
      dateMenu: false,
      selectMenu: false,
      dates: SHARED.DEFAULT_DATE,
      oneMonthDate: SHARED.DEFAULT_SEVEN_DAYS,
      defaultDate: SHARED.DEFAULT_DATE,
      language: uiHelper.getLanguage(),
      currentCurrency: uiHelper.getCurrency(),
      validator: formValidator,
      shared: SHARED,
      selectedTab: 'RUNNING',
      historyProvider: '',
      historyGameType: 1,
      slotsProviderDdl: ddlHandler.getSlotsProvider(),
      sportsProviderDdl: ddlHandler.getSportsProvider(),
      liveProviderDdl: ddlHandler.getLiveProvider(),
      tableProviderDdl: ddlHandler.getTableProvider(),
      cricketProviderDdl: ddlHandler.getCricketProvider(),
      selectedList: [],
      pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10
      },
      historyDetail: {
        data: [
          {
            tip: 0
          }
        ]
      },
      searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 10
      }
    }
  },
  created() {
    this.getOverviewHistory(this.selectedTab)
    this.getDetailHistory()
  },
  computed: {
    turnoverTicket() {
      return this.$store.state.member.turnoverTicket.data
    }
  },
  watch: {
    turnoverTicket() {
      return this.$store.state.member.turnoverTicket.data
    }
  },
  methods: {
    getProgressBar(obj) {
      let ratio = 0;
      try {
        ratio = ((obj.current_progress == 0 && obj.total_rollover == 0) || (obj.current_progress >= obj.total_rollover)) ? 1 : obj.current_progress == 0 ? 0 : obj.current_progress / obj.total_rollover
        return ratio * 100;
      } catch (err) {
      }
    },
    setDetailsDialog(obj) {
      let ratio = 0;
      try {
        ratio = ((obj.current_progress == 0 && obj.total_rollover == 0) || (obj.current_progress >= obj.total_rollover)) ? 1 : obj.current_progress == 0 ? 0 : obj.current_progress / obj.total_rollover
        ratio = ratio * 100;
      } catch (err) {
      }
      this.detailObj = {
        'title': obj.remark,
        'deposit_amount': obj.request_amount,
        'bonus_amount': obj.bonus_amount,
        'to_req': obj.total_rollover,
        'to_completed': obj.current_progress,
        'to_create_time': obj.created_at,
        'to_ratio': ratio
      }
      this.detailDialog = true;
    },
    onChangeTab(type) {
      this.historyList = []
      this.selectedTab = type
      this.getOverviewHistory(this.selectedTab)
    },
    getOverviewHistory(is_running) {
      let filter = {
        is_served: is_running != "RUNNING" ? 1 : 0
      }
      this.$store.dispatch(`${MEMBER_TURNOVER_TICKET}`, {
        filter
      })
    },
    clearFilter() {
      this.dates = []
      this.onChangeTab(this.selectedTab)
    }
  }
}
</script>

<style lang="scss">

.history-overview-wrapper {
  max-width: 1240px;
  margin: auto;
}

.trxn_history_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.mobile-history-table {
  .col-12 {
    min-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
  }
}

.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;

  .date-search {
    width: fit-content;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }

  .referral-table-header {
    color: #acacac;
  }
}

.date-search-mobile {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-input__append-inner {
    margin-top: 8px;
  }
}

.mobile-history-card {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.player-history-tab {
  width: 140px;
  margin-right: 15px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
}

.history-tab-mobile {
  width: fit-content !important;
  margin-right: 5px;
  min-width: fit-content !important;
  padding: 2px;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 12px !important;

  .mobile-icon {
    margin-right: 3px;
    width: 20px;
    height: 20px;
  }

  .mobile-icon-active {
    fill: #3a3a3a !important;

    path {
      fill: #fff !important;
    }
  }
}

.text-start {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.text-center {
  margin: auto;
}

.pt-4.pb-2.px-4.mobile-provider-padding.col.col-12 {
  padding-top: 0px !important;
}

.pt-4.pb-2.px-4.date-padding.col.col-12 {
  padding-bottom: 0px !important;
}

.provider-select {
  top: 13px;
}

.mobile-provider-select {
  top: 5px;
}

.mobile-provider-padding .v-input .v-label {
  top: 10px !important;
}

.provider-padding .v-input .v-label {
  top: 15px !important;
}

@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;

    .referral-table-header {
      color: #acacac;
    }
  }
}

.all-wallet-wrap {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
}

.bonus-wallet-wrap {
  display: block;
  width: 49.4%;
  margin: 0.3125rem 0;
  border-radius: 5px;

  &.progressing {
    background: linear-gradient(180deg, #52679c 0%, #353f5c 100%);
  }

  &.served {
    background-image: linear-gradient(180deg, #606779 0%, #454957 73%);
  }
}

.bonus-wallet-wrap .bonus-wallet {
  display: block;
  position: relative;
  //height: 9.0625rem;
  padding: 0.625rem;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -3px 2px rgb(183 183 183 / 10%);
}

.bonus-wallet-wrap .title {
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: .775rem !important;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;
}

.bonus-wallet-wrap .wallet-label, .bonus-wallet-wrap .wallet-label-hot, .bonus-wallet-wrap .wallet-label-open, .bonus-wallet-wrap .wallet-label-complete {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 3.5625rem;
  height: 3.5625rem;
  background: url(/static/image/turnover/wallet-new.svg) no-repeat top center/cover;
}

.bonus-wallet-wrap .wallet-label-hot {
  background: url(/static/image/turnover/wallet-hot.svg) no-repeat top center/cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bonus-wallet-wrap .wallet-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 0.625rem;
  border-radius: 0.25rem;
}

.bonus-wallet-wrap .wallet-inner-left {
  width: 5.125rem;
  height: 5.125rem;
  margin-right: 0.9375rem;
  overflow: hidden;
  border-radius: 0.25rem;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bonus-wallet-wrap .wallet-inner-right {
  display: block;
  position: relative;
  width: 75%;
}

.bonus-wallet-wrap .card-detail {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
}

.bonus-wallet-wrap .card-date {
  margin: auto 0;
  color: #b8b8b8;
  font-size: .625rem;
}

.bonus-wallet-wrap .detail-btn {
  display: flex;
  margin: auto 0.3125rem;
  padding: 0.125rem 0.25rem;
  border: 0.0625rem solid #a7bdf7;
  border-radius: 3px;
}

.bonus-wallet-wrap .detail-btn a {
  color: #a7bdf7;
  font-size: .625rem;
  text-decoration: none;
}

.bonus-wallet-wrap .discount {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  color: #f8cd69;
}

.bonus-wallet-wrap .discount .currency {
  margin: auto 0;
  padding-right: 0.3125rem;
  font-size: .875rem;
}

.bonus-wallet-wrap .discount .amount {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.5625rem;
}

.bonus-wallet-wrap .discount02 {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1.25rem 0 0.625rem;
  color: #fff;
  font-size: .75rem;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group {
  position: relative;
  width: 90%;
  margin: 0;
  border-bottom: 0 solid;
}

.bonus-wallet-wrap .wallet-inner-right .progress {
  height: 0.3125rem;
  margin-bottom: 0.3125rem;
  border-radius: 0.3125rem;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress {
  flex: 1 0 80%;
  height: 0.3125rem;
  margin-right: 0.625rem;
  border-radius: 0.3125rem;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress-bar {
  position: relative;
  width: 0;
  height: 100%;
  float: left;
  transition: width .6s ease;
  border-radius: 0.3125rem;
  background: linear-gradient(to right, #a56c0b 0%, #f8e67d 100%);
  box-shadow: 0 0 0.1875rem #ffea65;
}

.progress-bar::after {
  content: "";
  position: absolute;
  top: 0.0625rem;
  right: 0.125rem;
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 3px;
  background-color: #fff;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group > span {
  display: inline-block;
  width: 49%;
  color: #b8b8b8;
  font-size: .8125rem;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-all;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group > span {
  display: inline-block;
  width: 49%;
  color: #b8b8b8;
  font-size: .8125rem;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-all;
}

.bonus-wallet-wrap .wallet-inner-right .vip-next {
  float: right;
  text-align: right;
}

.pop-wrap td, .pop-wrap th {
  padding: 6px;
  border: 1px solid rgba(51, 51, 51, 0.5);
}

.pop-wrap table {
  border-collapse: collapse;
}

.bg-pop {
  border-bottom: 1px solid #dfc98d;
  background: #c9a33d;
  word-break: break-word;
}


@media (max-width: 757px) {
  .bonus-wallet-wrap {
    width: 100%;
  }

  .bonus-wallet-wrap .wallet-inner {
    padding-top: 0;
  }

  .bonus-wallet-wrap .wallet-inner-left {
    width: 2.125rem;
    height: 2.125rem;
  }
}
</style>
